// components - layouts
import Page from "components/layouts/Page/Page.js";

const ConnectionErrorPage = () => {
  return (
    <Page
      className={"align-items-center container-fluid d-flex justify-content-center"}
      style={{ height: "100vh", width: "100vw" }}
    >
      <div
        className={(
          "bg-white p-2 rounded kit-border-shadow " +
          "d-flex flex-column justify-content-center"
        )}
      >
        <div
          className={(
            "bg-light h5 rounded " +
            "align-items-center d-flex flex-column justify-content-center " +
            "text-break text-center text-danger text-wrap"
          )}
          style={{
            minHeight: "5rem",
            overflow: "auto",
            wordBreak: "break-word",
          }}
        >
          {"Unable to connect to the application service."}
          <span className={"h6 mt-1 text-dark"}>
            {"Please check your internet connection or try again later."}
          </span>
        </div>
      </div>
    </Page>
  );
};
export default ConnectionErrorPage;